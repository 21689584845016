import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorOne: false,
  errorTwo: false,
  errorThree: false,
  errorFour: false,
  errorFive: false,
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
export const { setError } = errorSlice.actions;
export default errorSlice.reducer;
