import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  a_client_type: { value: "ecommerce", label: "E-Commerce/SaaS Revenue" },
  b_event_type: { value: "leads", label: "Leads" },
  c_online_revenue_monthly: 20000,
  d_online_revenue_annual: 240000,
  e_online_event_monthly: 1000,
  f_online_event_annual: 12000,
  g_event_worth_monthly: 20,
  h_event_worth_annual: 240,
  i_revenue_earned_monthly: 20000,
  j_revenue_earned_annual: 240000,
  k_online_ad_cost_monthly: 10000,
  l_online_ad_cost_annual: 120000,
  m_financial_method: { value: "cash", label: "Cash" },
  n_financial_interest_rate_monthly: 2,
  o_financial_interest_rate_annual: 24,
  p_marketing_agency_cost_monthly: 4500,
  q_marketing_agency_cost_annual: 54000,
  r_ad_spend_with_rr: 200,
};

const inputSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    addInputData: (state, action) => {
      return { ...state, ...action?.payload };
    },
  },
});

export const { addInputData } = inputSlice.actions;

export default inputSlice.reducer;
